<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icon }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5" style="color: red">仕入実績（削除）</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status == 'error'">
              <div
                v-for="msg in apierror.messages"
                :key="msg"
              >
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row align="center">
              <v-col cols="12">
                <v-text-field v-model="selectedPurchased.item_code" label="単品コード" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedPurchased.sup_code" label="仕入先名" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="selectedPurchased.purc_tip" label="仕入単価" readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedPurchased.purc_qty" label="数量" readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="selectedPurchased.purc_amount" label="総金額" readonly></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn color="success" :loading="submitStatus" @click="deleteItem(selectedPurchased)">
            YES
          </v-btn>
          <v-btn color="error" class="mx-2" @click="closeForm()">
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

export default {
  props: ['icon', 'id'],
  data: () => ({
    dateMenu: false,
    submitStatus: false,
    dialog: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('purchasedStore', ['selectedPurchased']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('purchasedStore', ['loadPurchased', 'deletePurchased']),

    openForm() {
      this.setOverlayStatus(true)
      this.loadPurchased(this.id)
        .catch(error => {
          this.submitStatus = false
          this.apierror.status = 'error'
          this.apierror.messages = error.response.data.message
        })
        .finally(() => {
          this.setOverlayStatus(false)
          this.dialog = true
        })
    },

    deleteItem(selectedPurchased) {
      this.apierror.messages = []
      this.submitStatus = true
      this.deletePurchased(selectedPurchased.id)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.submitStatus = false
        })
    },

    closeForm() {
      this.$emit('cancel')
      this.apierror.messages = []
      this.dialog = false
    },
  },
}
</script>
