<template>
  <div id="purchase-list">
    <v-card>
      <v-card-title>仕入実績（一覧）</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-autocomplete
            v-model="item_code"
            :items="itemCodes"
            placeholder="単品コード"
            outlined
            clearable
            dense
            hide-details
            class="me-3 mb-4"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-autocomplete
            v-model="sup_code"
            :items="supCodes"
            placeholder="仕入先コード"
            clearable
            outlined
            hide-details
            dense
            class="me-3 mb-4"
          >
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                placeholder="仕入日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="start_date"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date"
                placeholder="仕入日To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="end_date"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'purchase-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
            :loading="exportStatus"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="datatable"
        :headers="headers"
        :items="purchasedList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.item_code`]="{ item }">
          <v-tooltip v-if="item.item_code" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              > {{ item.item_code }}</span>
            </template>
            <span>{{ item.sup_code }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.item_name`]="{ item }">
          <v-tooltip v-if="item.item_name" bottom>
            <template v-slot:activator="{ on, attrs }">
              <p
                class="class-with-title"
                style="margin: 0; width:14rem; overflow: hidden; text-overflow:ellipsis;white-space: nowrap;"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.item_name }}
              </p>
            </template>
            <span>{{ item.item_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.purc_tip`]="{ item }">
          <label>{{ item.purc_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`item.purc_qty`]="{ item }">
          <label>{{ item.purc_qty.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`item.purc_amount`]="{ item }">
          <label>{{ item.purc_amount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <delete-form
              :id="item.id"
              class="py-2 pl-1"

              :icon="icons.mdiDelete"
              @deleted="refreshList"
              @cancel="refreshList"
            ></delete-form>
          </div>
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              style="background: #fafafa"
              :colspan="6"
            >
              <label>合計価格(円)</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="1"
            ></td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ tableTotalPrice }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="1"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  mdiCalendar, mdiPlus, mdiExportVariant, mdiPencil, mdiDelete,
} from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'
import DeleteForm from './PurchaseDelete.vue'
/* eslint-disable */

export default {
  components: {
    DeleteForm,
  },
  data: () => ({
    exportStatus: false,
    item_code: '',
    sup_code: '',
    supCodes: [],
    set_code:'',
    subs_code:'',
    dateStartMenu: false,
    start_date: '',
    dateEndMenu: false,
    end_date: '',
    search: '',
    icons: {
      mdiCalendar,
      mdiPlus,
      mdiExportVariant,
      mdiPencil,
      mdiDelete,
    },
    headers: [
      {
        text: 'ID',
        value: 'id',
        align: 'left',
        width: '5%',
        fixed: true,
      },
      {
        text: '仕入日',
        value: 'purc_date',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '単品コード',
        value: 'item_code',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },

      {
        text: '単品名',
        value: 'item_name',
        width: '20%',
        align: 'left',
        fixed: true,
      },
      {
        text: '仕入先名',
        value: 'sup_abbr',
        width: '12%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '単価',
        value: 'purc_tip',
        width: '8%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '数量',
        value: 'purc_qty',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '総金額',
        value: 'purc_amount',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },

      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'center',
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('purchasedStore', ['purchasedList']),
    ...mapState('itemStore', ['itemCodes']),
    ...mapState('supplierStore', ['supplierList']),
    tableTotalPrice() {
      let totalPrice = 0
      this.purchasedList.forEach(pl => {
        totalPrice += pl.purc_amount
      })

      return totalPrice.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
  },
  watch: {
    //単品コード
    item_code(newValue) {
      this.changeRouteQuery('item_code', newValue)
      this.refreshList()
      if (newValue !== null && newValue !== '') {
        this.set_code = ''
        this.subs_code = ''
      }
    },

    //セットコード
    set_code(newValue) {
      this.changeRouteQuery('set_code', newValue)
      this.refreshList()
      if (newValue !== null && newValue !== '') {
        this.item_code = ''
        this.subs_code = ''
      }
    },

    //定期便コード
    subs_code(newValue) {
      this.changeRouteQuery('subs_code', newValue)
      this.refreshList()
      if (newValue !== null && newValue !== '') {
        this.set_code = ''
        this.item_code = ''
      }
    },

    sup_code(newValue) {
      this.changeRouteQuery('sup_code', newValue)
      this.refreshList()
    },
    start_date(newValue) {
      this.changeRouteQuery('start_date', newValue)
      this.refreshList()
    },
    end_date(newValue) {
      this.changeRouteQuery('end_date', newValue)
      this.refreshList()
    },
  },
  created() {
    this.changeRouteQuery()
    this.loadQuery()
    this.refreshList()
  },
  destroyed() {
    this.clearPurchasedList()
    this.clearItemCodes()
    this.clearSupplierList()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('purchasedStore', ['loadPurchasedList']),
    ...mapActions('itemStore', ['loadItemCodes']),
    ...mapActions('supplierStore', ['loadSupplierList']),
    ...mapMutations('purchasedStore', ['clearPurchasedList']),
    ...mapMutations('itemStore', ['clearItemCodes']),
    ...mapMutations('supplierStore', ['clearSupplierList']),

    refreshList() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadItemCodes(),
        this.loadSupplierList(),
        this.loadPurchasedList(this.$route.query),
      ]).then(() => {
        this.getSupsCode()
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    getSupsCode() {
      this.supCodes=[]
      this.supplierList.forEach((sl, i) => {
        if (sl.sup_code) {
          this.supCodes.push(sl.sup_code)
        }
      });
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({ path: this.$route.path, query })
    },

    loadQuery() {
      this.item_code = this.$route.query.item_code ?? ''
      this.sup_code = this.$route.query.sup_code ?? ''
      this.start_date = this.$route.query.start_date ?? ''
      this.end_date = this.$route.query.end_date ?? ''
    },

    editItem(item) {
      this.$router.push({ name: 'purchase-edit', params: { id: item.id } })
    },
    downloadCSV() {
      this.exportStatus = true
      // eslint-disable-next-line no-useless-concat
      let csv = '\ufeff' + 'ID,仕入日,単品コード,単品名,仕入先名,単価,数量,総金額\n'
      this.purchasedList.forEach(el => {
        const Id = el.id ?? ''
        const purcDate = el.purc_date ?? ''
        const itemCode = el.item_code ?? ''
        const itemName = el.item_name ?? ''
        const supAbbr = el.sup_abbr ?? ''
        const purcTip = el.purc_tip ? el.purc_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ''
        const purcQty = el.purc_qty ? el.purc_qty.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ''
        const purcAmount = el.purc_amount ? el.purc_amount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ''

        const line = `"${Id}","${purcDate}","${itemCode}","${itemName}","${supAbbr}","${purcTip}","${purcQty}","${purcAmount}"\n`
        csv += line
      })

      const blob = new Blob([csv], {
        type: 'text/csv',
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)

      const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
      const newstr = nowstr.replace(/(-|:|T)/g, '')

      link.download = '仕入実績一覧'.concat('-', newstr, '.csv')
      link.click()

      this.exportStatus = false
      
    },
  },
}
</script>

<style>
.table-filed {
  white-space: nowrap;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
</style>
